import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/Home.css";
import video from "../assets/cars.mp4";
import harrier from "../assets/harrier.jpg";
import audi from "../assets/2023 Audi A5.jpg";

function Home() {
  const testimonials = [
    {
      text: "The Grand Auto made buying my car a breeze. Great service and amazing selection!",
      author: "John Doe",
    },
    {
      text: "Perfect. Great service and amazing selection!",
      author: "Jane Doe",
    },
    {
      text: "I love my new car! The process was smooth, and the delivery was super quick!",
      author: "Michael Smith",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="hero">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>Find Your Dream Car Today</h1>
          <p>Explore our premium vehicles at unbeatable prices.</p>
          <Link to="/vehicles"><button>Browse Vehicles</button></Link>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about">
        <h2>About The Grand Auto</h2>
        <p>
          At The Grand Auto, we specialize in high-quality vehicles tailored to fit your needs. We offer top-rated cars
          that suit your lifestyle and budget.
        </p>
        <Link to={'/about'}><button>Learn More</button></Link>
      </section>

      {/* Featured Vehicles Section */}
      <section className="featured-vehicles">
        <h2>Featured Vehicles</h2>
        <div className="vehicle-list">
          <div className="vehicle-card">
            <img src={harrier} alt="Vehicle 1" />
            <h3>2023 Toyota Harrier</h3>
            <p>ksh 4,407,000</p>
            <Link to="/vehicles"><button>View more</button></Link>
          </div>
          <div className="vehicle-card">
            <img src={audi} alt="Vehicle 2" />
            <h3>2023 Audi A5</h3>
            <p>ksh 5,065,316</p>
            <Link to="/vehicles"><button>View more</button></Link>
          </div>
          {/* Add more vehicles here */}
        </div>
        <Link to="/vehicles"><button>See All Vehicles</button></Link>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose Us</h2>
        <div className="benefits">
          <div className="benefit">
            <h3>Certified Vehicles</h3>
            <p>Only the best, fully-inspected cars make it to our lot.</p>
          </div>
          <div className="benefit">
            <h3>Great Financing Options</h3>
            <p>We offer affordable financing for everyone.</p>
          </div>
          <div className="benefit">
            <h3>Quick Delivery</h3>
            <p>Your new car delivered right to your door.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Customers Say</h2>
        <div className="testimonial-card">
          <p>"{testimonials[currentIndex].text}"</p>
          <p>- {testimonials[currentIndex].author}</p>
          <div className="testimonial-controls">
            <button onClick={handlePrev}>&#8592; Previous</button>
            <button onClick={handleNext}>Next &#8594;</button>
          </div>
        </div>
      </section>

      {/* Contact Info Section */}
      <section className="contact-info">
        <p>Have questions? Call us at: (123) 456-7890</p>
        <Link to="/contact"><button>Contact Us</button></Link>
      </section>
    </div>
  );
}

export default Home;
