import React from "react";
import "../styles/About.css"; // Assuming you style this component with a CSS file
import car from "../assets/cars.jpeg"
import trusted from "../assets/icons/trusted.svg"
import user from "../assets/icons/user.svg"
import selections from "../assets/icons/selections.svg"
import filter from "../assets/icons/filter.svg"
import payment from "../assets/icons/payment.svg"
import support from "../assets/icons/support.svg"
import journey from "../assets/journey.jpg"
import { Link } from "react-router-dom";


const About = () => {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-overlay">
          <h1>Driving Excellence, One Vehicle at a Time</h1>
          <p>
            Discover the ultimate platform to buy and sell vehicles effortlessly.
          </p>
          <Link to="/vehicles"><button className="cta-button">Explore Vehicles</button></Link>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="mission-section">
        <div className="container">
          <div className="text-content">
            <h2>Our Mission</h2>
            <p>
              At The Grand Auto, our mission is to connect car enthusiasts with
              trusted sellers and buyers, offering a seamless, reliable
              platform for everyone. We are committed to empowering our users
              with tools that simplify the process of finding and selling their
              dream vehicles.
            </p>
          </div>
          <div className="image-content">
            <img src={car} alt="Our Mission" />
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="choose-us-section">
        <h2>Why Choose Us</h2>
        <div className="features-grid">
          <div className="feature">
            <img src={selections} alt="Wide Selection" />
            <h3>Wide Selection</h3>
            <p>
              Explore a diverse range of vehicles to find the one that matches
              your preferences perfectly.
            </p>
          </div>
          <div className="feature">
            <img src={trusted} alt="Trusted Sellers" />
            <h3>Trusted Sellers</h3>
            <p>
              Our platform ensures every seller meets high standards of
              reliability and trust.
            </p>
          </div>
          <div className="feature">
            <img src={user} alt="User-Friendly Interface" />
            <h3>User-Friendly Interface</h3>
            <p>
              Navigate effortlessly with our intuitive and easy-to-use design.
            </p>
          </div>
          <div className="feature">
            <img src={filter} alt="Advanced Filters" />
            <h3>Advanced Filters</h3>
            <p>
              Find exactly what you need with powerful search and filtering
              options.
            </p>
          </div>
          <div className="feature">
            <img src={payment} alt="Secure Transactions" />
            <h3>Secure Transactions</h3>
            <p>
              Conduct your deals with confidence and safety on our platform.
            </p>
          </div>
          <div className="feature">
            <img src={support} alt="Expert Support" />
            <h3>Expert Support</h3>
            <p>
              Reach out to our team for assistance every step of the way.
            </p>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="story-section">
        <div className="container">
          <div className="text-content">
            <h2>The Grand Auto Journey</h2>
            <p>
              The Grand Auto was born from a passion for automobiles and a
              desire to create a trustworthy marketplace for car lovers. Over the years, we have grown into a community-driven
              company, where every vehicle has a story and every transaction
              builds trust.
            </p>
          </div>
          <div className="image-content">
            <img src={journey} alt="Our Story" />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonials-carousel">
          <div className="testimonial">
            <p>
              "The Grand Auto made finding my dream car so easy. The advanced
              filters saved me hours of searching!"
            </p>
            <h4>- John D.</h4>
          </div>
          <div className="testimonial">
            <p>
              "Selling my car was seamless. The platform's security features
              gave me peace of mind."
            </p>
            <h4>- Sarah P.</h4>
          </div>
          <div className="testimonial">
            <p>
              "I was impressed by the customer support. They guided me every
              step of the way."
            </p>
            <h4>- Ahmed R.</h4>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Ready to Find Your Next Car?</h2>
        <div className="cta-buttons">
          <Link to="/vehicles"><button className="cta-button">Browse Vehicles</button></Link>
        </div>
      </section>
    </div>
  );
};

export default About;
