import React from "react";
import { useLocation, Link } from "react-router-dom";
import "../styles/VehicleDetails.css";

function VehicleDetails() {
  const location = useLocation();
  const vehicle = location.state?.vehicle;

  if (!vehicle) {
    return <p>Vehicle not found!</p>;
  }

  const { specifications, interiorFeatures, safetyFeatures } = vehicle;

  return (
    <div className="vehicle-details-page">
      <header className="vehicle-details-header">
        <h1>{vehicle.name}</h1>
      </header>

      <div className="vehicle-details-content">
        <div className="vehicle-image">
          <img src={vehicle.image} alt={vehicle.name} />
        </div>

        <div className="vehicle-info">
          <p><strong>Make:</strong> {vehicle.make}</p>
          <p><strong>Model:</strong> {vehicle.model}</p>
          <p><strong>Year:</strong> {vehicle.year}</p>
          <p><strong>Price:</strong> Ksh {vehicle.price.toLocaleString()}</p>
          <p><strong>Category:</strong> {vehicle.category}</p>
        </div>
      </div>

      <div className="feature-cards">
        <div className="card">
          <h3>Specifications:</h3>
          <p><strong>Engine:</strong> {specifications.engine}</p>
          <p><strong>Transmission:</strong> {specifications.transmission}</p>
          <p><strong>Fuel Efficiency:</strong> {specifications.fuelEfficiency}</p>
          <p><strong>Mileage:</strong> {specifications.mileage}</p>
        </div>

        <div className="card">
          <h3>Interior Features:</h3>
          <p><strong>Seating Capacity:</strong> {interiorFeatures.seatingCapacity}</p>
          <p><strong>Infotainment:</strong> {interiorFeatures.infotainment}</p>
          <p><strong>Upholstery:</strong> {interiorFeatures.upholstery}</p>
        </div>

        <div className="card">
          <h3>Safety Features:</h3>
          <p><strong>Airbags:</strong> {safetyFeatures.airbags}</p>
          <p><strong>Other Features:</strong> {safetyFeatures.abs}</p>
        </div>
      </div>
      <Link to="/contact"><button>Contact for More Info</button></Link>
    </div>
  );
}

export default VehicleDetails;
