import React, { useState, useEffect, useMemo } from "react";
import "../styles/Vehicles.css";
import { Link, useNavigate } from "react-router-dom";
import harrier from "../assets/harrier.jpg";
import audi from "../assets/2023 Audi A5.jpg";

function Vehicles() {
  const placeholderImage = "https://via.placeholder.com/300x200.png?text=Vehicle+Image+Not+Available";

  const vehicles = useMemo(() => [
    {
      id: 1,
      name: "2023 Toyota Harrier",
      price: 4407000,
      year: 2023,
      make: "Toyota",
      model: "Harrier",
      category: "SUV",
      image: harrier || placeholderImage,
      specifications: {
        engine: "2.0L 4-cylinder",
        transmission: "Automatic",
        fuelEfficiency: "15 km/L",
        mileage: "30,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "9-inch touchscreen, Bluetooth, Navigation",
        upholstery: "Leather",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "ABS, Lane Assist",
      },
    },
    {
      id: 2,
      name: "2023 Audi A5",
      price: 5065316,
      year: 2023,
      make: "Audi",
      model: "A5",
      category: "Sedan",
      image: audi || placeholderImage,
      specifications: {
        engine: "2.0L Turbocharged 4-cylinder",
        transmission: "7-speed S-Tronic",
        fuelEfficiency: "13 km/L",
        mileage: "12,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "10.1-inch touchscreen, Audi MMI, Navigation",
        upholstery: "Premium Leather",
      },
      safetyFeatures: {
        airbags: "Front and curtain",
        abs: "ABS, Lane Keep Assist, Adaptive Cruise Control",
      },
    },
    {
      id: 3,
      name: "2022 Tesla Model 3",
      price: 7200000,
      year: 2022,
      make: "Tesla",
      model: "Model 3",
      category: "Sedan",
      image: "https://media.carsandbids.com/cdn-cgi/image/width=2080,quality=70/c51905b0000b639a185eeb080dd879bf007f5604/photos/KmbJJPpr-xSjnwbxAUW-(edit).jpg?t=167103268250",
      specifications: {
        engine: "Electric Motor",
        transmission: "1-speed Automatic",
        fuelEfficiency: "Electric equivalent: 150 MPGe",
        mileage: "20,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "15-inch touchscreen, Bluetooth, Tesla Apps",
        upholstery: "Vegan Leather",
      },
      safetyFeatures: {
        airbags: "All-around airbags",
        abs: "Autopilot, Collision Avoidance, Emergency Braking",
      },
    },
    {
      id: 4,
      name: "2021 BMW X5",
      price: 8600000,
      year: 2021,
      make: "BMW",
      model: "X5",
      category: "SUV",
      image: "https://platform.cstatic-images.com/in/v2/stock_photos/f40ffbad-2834-477b-8033-450393eb06c6/5533e1a1-5f66-44e5-ab3b-3573cde5a261.png" || placeholderImage,
      specifications: {
        engine: "3.0L Inline-6 Turbocharged",
        transmission: "8-speed Automatic",
        fuelEfficiency: "12 km/L",
        mileage: "40,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "12.3-inch touchscreen, Wireless CarPlay",
        upholstery: "Luxury Leather",
      },
      safetyFeatures: {
        airbags: "Front, side, curtain",
        abs: "Adaptive Cruise Control, Lane Keeping Assist",
      },
    },
    {
      id: 5,
      name: "2020 Mercedes-Benz GLE",
      price: 9500000,
      year: 2020,
      make: "Mercedes-Benz",
      model: "GLE",
      category: "SUV",
      image: "https://carsforsale.co.ke/wp-content/uploads/2024/09/2020-Mercedes-Benz-GLE-400D-AMG-4MATIC-7.jpg" || placeholderImage,
      specifications: {
        engine: "3.0L Inline-6 Turbocharged",
        transmission: "9-speed Automatic",
        fuelEfficiency: "11 km/L",
        mileage: "50,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "MBUX System, 12.3-inch screens",
        upholstery: "Nappa Leather",
      },
      safetyFeatures: {
        airbags: "Front, side, curtain",
        abs: "Pre-Safe, Blind Spot Assist, Lane Assist",
      },
    },
    {
      id: 6,
      name: "2023 Honda Civic",
      price: 2900000,
      year: 2023,
      make: "Honda",
      model: "Civic",
      category: "Sedan",
      image: "https://cdn.motor1.com/images/mgl/W81RXg/s1/honda-civic-sedan-e-hev-2023.jpg" || placeholderImage,
      specifications: {
        engine: "1.5L Turbocharged 4-cylinder",
        transmission: "CVT",
        fuelEfficiency: "18 km/L",
        mileage: "5,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "7-inch touchscreen, Android Auto, Apple CarPlay",
        upholstery: "Fabric",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "Honda Sensing, Lane Keep Assist",
      },
    },
    {
      id: 7,
      name: "2022 Ford Mustang",
      price: 6500000,
      year: 2022,
      make: "Ford",
      model: "Mustang",
      category: "Sports",
      image: "https://di-uploads-pod45.dealerinspire.com/pearsonford/uploads/2023/04/2022-Ford-Mustang-GT-Model-Left.jpg" || placeholderImage,
      specifications: {
        engine: "5.0L V8",
        transmission: "6-speed Manual",
        fuelEfficiency: "8 km/L",
        mileage: "10,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "4 seats",
        infotainment: "8-inch Sync 3 system",
        upholstery: "Leather",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "Rear-view Camera, Lane Assist",
      },
    },
    {
      id: 8,
      name: "2023 Toyota Corolla",
      price: 3300000,
      year: 2023,
      make: "Toyota",
      model: "Corolla",
      category: "Sedan",
      image: "https://www.smarttoyota.com/blogs/2398/wp-content/uploads/2023/01/white_se_34-1024x480.png" || placeholderImage,
      specifications: {
        engine: "1.8L 4-cylinder",
        transmission: "CVT",
        fuelEfficiency: "16 km/L",
        mileage: "5,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "7-inch touchscreen, Apple CarPlay, Android Auto",
        upholstery: "Fabric",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "Toyota Safety Sense, Lane Assist",
      },
    },
    {
      id: 9,
      name: "2021 Chevrolet Tahoe",
      price: 7600000,
      year: 2021,
      make: "Chevrolet",
      model: "Tahoe",
      category: "SUV",
      image: "https://di-uploads-pod3.dealerinspire.com/newtonchevybuickgmc/uploads/2021/07/2021-Chevy-Tahoe-RST-Model-Left.jpg" || placeholderImage,
      specifications: {
        engine: "5.3L V8",
        transmission: "10-speed Automatic",
        fuelEfficiency: "9 km/L",
        mileage: "30,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "7 seats",
        infotainment: "10.2-inch touchscreen, Apple CarPlay, Android Auto",
        upholstery: "Leather",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "Rear Cross-Traffic Alert, Adaptive Cruise Control",
      },
    },
    {
      id: 10,
      name: "2020 Nissan Altima",
      price: 3100000,
      year: 2020,
      make: "Nissan",
      model: "Altima",
      category: "Sedan",
      image: "https://www.bedfordnissan.com/blogs/1502/wp-content/uploads/2020/07/2020_Nissan_Altima_S.png" || placeholderImage,
      specifications: {
        engine: "2.5L 4-cylinder",
        transmission: "CVT",
        fuelEfficiency: "14 km/L",
        mileage: "50,000 km",
      },
      interiorFeatures: {
        seatingCapacity: "5 seats",
        infotainment: "8-inch touchscreen, Apple CarPlay, Android Auto",
        upholstery: "Cloth",
      },
      safetyFeatures: {
        airbags: "Front and side",
        abs: "ProPilot Assist, Lane Departure Warning",
      },
    },
  ], []);

  const [filters, setFilters] = useState({
    category: "All",
    year: "All",
    make: "All",
    model: "",
  });

  const navigate = useNavigate();

  // Handle key combination (e.g., "Ctrl + Shift + A")
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for the key combination (Ctrl + Shift + A)
      if (e.ctrlKey && e.shiftKey && e.key === "A") {
        // Navigate to admin page and pass vehicles data as state
        navigate("/admin", { state: { vehicles } });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, vehicles] );

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    return (
      (filters.category === "All" || vehicle.category === filters.category) &&
      (filters.year === "All" || vehicle.year === parseInt(filters.year)) &&
      (filters.make === "All" || vehicle.make === filters.make) &&
      (filters.model === "" || vehicle.model.toLowerCase().includes(filters.model.toLowerCase()))
    );
  });

  return (
    <div className="vehicles-page">
      <header className="vehicles-header">
        <h1>Explore Our Vehicles</h1>
      </header>

      <div className="vehicles-content">
        <aside className="filter-sidebar">
          <h3>Filter Vehicles</h3>
          <label htmlFor="category">Category</label>
          <select id="category" onChange={(e) => handleFilterChange("category", e.target.value)}>
            <option value="All">All</option>
            <option value="SUV">SUV</option>
            <option value="Sedan">Sedan</option>
          </select>

          <label htmlFor="year">Year</label>
          <select id="year" onChange={(e) => handleFilterChange("year", e.target.value)}>
            <option value="All">All</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>

          <label htmlFor="make">Make</label>
          <select id="make" onChange={(e) => handleFilterChange("make", e.target.value)}>
            <option value="All">All</option>
            <option value="Toyota">Toyota</option>
            <option value="Audi">Audi</option>
          </select>

          <label htmlFor="model">Model</label>
          <input
            type="text"
            id="model"
            placeholder="Search model..."
            onChange={(e) => handleFilterChange("model", e.target.value)}
          />
        </aside>

        <main className="vehicles-grid">
          {filteredVehicles.map((vehicle) => (
            <div key={vehicle.id} className="vehicle-card">
              <img src={vehicle.image} alt={vehicle.name} />
              <h3>{vehicle.name}</h3>
              <p>Price: ksh {vehicle.price.toLocaleString()}</p>
              <Link to={`/vehicle-details/${vehicle.id}`} state={{ vehicle }}>
                <button>View Details</button>
              </Link>
            </div>
          ))}
          {filteredVehicles.length === 0 && <p>No vehicles match your filters.</p>}
        </main>
      </div>
    </div>
  );
}

export default Vehicles;
