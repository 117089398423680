import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/Admin.css"; // Import the specific styles for this page

const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_PASSWORD;


const Admin = () => {
  // Get vehicles data passed through location
  const location = useLocation();
  const initialVehicles = location.state?.vehicles || [];

  const [vehicles, setVehicles] = useState(initialVehicles);
  const [vehicleForm, setVehicleForm] = useState({
    name: "",
    price: "",
    year: "",
    make: "",
    model: "",
    category: "",
    image: "",
    specifications: {
      engine: "",
      transmission: "",
      fuelEfficiency: "",
      mileage: "",
    },
    interiorFeatures: {
      seatingCapacity: "",
      infotainment: "",
      upholstery: "",
    },
    safetyFeatures: {
      airbags: "",
      abs: "",
    },
  });
  const [editingId, setEditingId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");

  // Handle password input and authentication
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password. Access denied.");
    }
  };

  // Form field change handler
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [key, subKey] = name.split(".");
      setVehicleForm((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          [subKey]: value,
        },
      }));
    } else {
      setVehicleForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Add or update vehicle
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editingId) {
      // Update existing vehicle
      setVehicles((prev) =>
        prev.map((vehicle) =>
          vehicle.id === editingId ? { ...vehicleForm, id: editingId } : vehicle
        )
      );
      setEditingId(null);
    } else {
      // Add new vehicle
      const newVehicle = {
        ...vehicleForm,
        id: vehicles.length ? vehicles[vehicles.length - 1].id + 1 : 1, // Increment ID
      };
      setVehicles([...vehicles, newVehicle]);
    }
    setVehicleForm({
      name: "",
      price: "",
      year: "",
      make: "",
      model: "",
      category: "",
      image: "",
      specifications: {
        engine: "",
        transmission: "",
        fuelEfficiency: "",
        mileage: "",
      },
      interiorFeatures: {
        seatingCapacity: "",
        infotainment: "",
        upholstery: "",
      },
      safetyFeatures: {
        airbags: "",
        abs: "",
      },
    });
  };

  // Populate form with vehicle data for editing
  const handleEditVehicle = (id) => {
    const vehicleToEdit = vehicles.find((vehicle) => vehicle.id === id);
    setVehicleForm(vehicleToEdit);
    setEditingId(id);
  };

  // Delete vehicle
  const handleDeleteVehicle = (id) => {
    setVehicles((prev) => prev.filter((vehicle) => vehicle.id !== id));
  };

  if (!isAuthenticated) {
    return (
      <div className="password-protect-container">
        <h2>Admin Login</h2>
        <form onSubmit={handlePasswordSubmit}>
          <label htmlFor="password">Enter Admin Password:</label>
          <input
            type="password"
            id="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    );
  }

  return (
    <div className="admin-container">
      <h2 className="admin-title">Admin Panel - Vehicle Management</h2>

      {/* Add / Edit Vehicle Form */}
      <form className="vehicle-form" onSubmit={handleFormSubmit}>
        <h3>{editingId ? "Edit Vehicle" : "Add New Vehicle"}</h3>
        <div className="form-row">
          <h3>DETAILS</h3>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={vehicleForm.name || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={vehicleForm.price || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Year:</label>
          <input
            type="number"
            name="year"
            value={vehicleForm.year || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Make:</label>
          <input
            type="text"
            name="make"
            value={vehicleForm.make || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Model:</label>
          <input
            type="text"
            name="model"
            value={vehicleForm.model || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Category:</label>
          <input
            type="text"
            name="category"
            value={vehicleForm.category || ""}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="form-row">
          <label>Image URL:</label>
          <input
            type="text"
            name="image"
            value={vehicleForm.image || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <h3>SPECIFICATIONS</h3>
          <label>Engine:</label>
          <input
            type="text"
            name="specifications.engine"
            value={vehicleForm.specifications?.engine || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>transmission:</label>
          <input
            type="text"
            name="specifications.transmission"
            value={vehicleForm.specifications?.transmission || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>Fuel Efficiency:</label>
          <input
            type="text"
            name="specifications.fuelEfficiency"
            value={vehicleForm.specifications?.fuelEfficiency || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>Mileage:</label>
          <input
            type="text"
            name="specifications.mileage"
            value={vehicleForm.specifications?.mileage || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <h3>INTERIOR FEATURES</h3>
          <label>Seating Capacity:</label>
          <input
            type="text"
            name="interiorFeatures.seatingCapacity"
            value={vehicleForm.interiorFeatures?.seatingCapacity || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>Infotainment:</label>
          <input
            type="text"
            name="interiorFeatures.infotainment"
            value={vehicleForm.interiorFeatures?.infotainment || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>Upholstery:</label>
          <input
            type="text"
            name="interiorFeatures.upholstery"
            value={vehicleForm.interiorFeatures?.upholstery || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <h3>SAFETY FEATURES</h3>
          <label>Airbags:</label>
          <input
            type="text"
            name="safetyFeatures.airbags"
            value={vehicleForm.safetyFeatures?.airbags || ""}
            onChange={handleFormChange}
          />
        </div>
        <div className="form-row">
          <label>ABS:</label>
          <input
            type="text"
            name="safetyFeatures.abs"
            value={vehicleForm.safetyFeatures?.abs || ""}
            onChange={handleFormChange}
          />
        </div>
        {/* Add other fields similarly */}
        <button type="submit" className="form-button">
          {editingId ? "Update Vehicle" : "Add Vehicle"}
        </button>
      </form>

      {/* Vehicle List */}
      <h3 className="vehicle-list-title">Existing Vehicles</h3>
      <ul className="vehicle-list">
        {vehicles.map((vehicle) => (
          <li className="vehicle-item" key={vehicle.id}>
            <h4>
              {vehicle.name} ({vehicle.year})
            </h4>
            <p>Price: {vehicle.price}</p>
            <p>Make: {vehicle.make}</p>
            <p>Model: {vehicle.model}</p>
            <div className="button-group">
              <button
                onClick={() => handleEditVehicle(vehicle.id)}
                className="edit-button"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteVehicle(vehicle.id)}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Admin;
