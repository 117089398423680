import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Using Routes and Route from v6
import ScrollToTop from './components/scrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Admin from './components/Admin';
import Home from './components/Home';
import Vehicles from './components/Vehicles';
import Contact from './components/Contact';
import VehicleDetails from './components/VehicleDetails';
import About from './components/About';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Header appears on every page */}
        <Header />
        <ScrollToTop />
        {/* Main content for the pages */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicle-details/:vehicleId" element={<VehicleDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>

        {/* Footer appears on every page */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
