import React from 'react';
import ContactForm from './ContactForm';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>If you have any questions, or would like to inquire about our services, feel free to reach out to us using the form below or through our contact details.</p>

      <div className="contact-container">
        {/* Contact Form Section */}
        <div className="contact-form-section">
          <ContactForm />
        </div>

        {/* Contact Information Section */}
        <div className="contact-info">
          <h2>Contact Information</h2>
          <p>Email: example@gmail.com</p>
          <p>Phone: phone number</p>
          <p>Address: address</p>

          {/* Social Media Links */}
          <div className="social-media">
            <h3>Follow Us:</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} />
              </a>
              <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                <img
                    src="/x.svg"
                    alt="Close Icon"
                    style={{ width: '50px', height: '50px', cursor: 'pointer', transition: 'filter 0.3s ease' }}
                    className="icon-image"
                    />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
